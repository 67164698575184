import { h } from "preact";

export const NoScreenIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.1265 19.5409C19.5171 19.9314 20.1502 19.9314 20.5408 19.5409C20.9313 19.1504 20.9313 18.5172 20.5408 18.1267L4.12117 1.70708C3.73064 1.31655 3.09748 1.31655 2.70696 1.70708C2.31643 2.0976 2.31643 2.73077 2.70696 3.12129L3.68165 4.09599C3.25582 4.61393 3.00016 5.27708 3.00016 5.99994V13.9999C3.00016 15.6568 4.3433 16.9999 6.00016 16.9999H11.0002V18.9999H7.00016C6.44787 18.9999 6.00016 19.4477 6.00016 19.9999C6.00016 20.5522 6.44787 20.9999 7.00016 20.9999L17.0002 20.9999C17.5524 20.9999 18.0002 20.5522 18.0002 19.9999C18.0002 19.4477 17.5524 18.9999 17.0002 18.9999H13.0002V16.9999H16.5856L19.1265 19.5409ZM14.5856 14.9999L5.11669 5.53103C5.04231 5.67088 5.00016 5.83049 5.00016 5.99994V13.9999C5.00016 14.5522 5.44787 14.9999 6.00016 14.9999H12.0002H14.5856ZM21.0002 13.9999C21.0002 14.7229 20.7444 15.3861 20.3185 15.9041L18.8835 14.4691C18.958 14.3292 19.0002 14.1695 19.0002 13.9999V5.99994C19.0002 5.44766 18.5524 4.99994 18.0002 4.99994H9.41432L7.41432 2.99994H18.0002C19.657 2.99994 21.0002 4.34309 21.0002 5.99994V13.9999Z"
                fill="#999999"
            />
        </svg>
    );
};
