import { h } from "preact";

export const AudioIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6V11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11V6Z"
                fill="white"
            />
            <path
                d="M6 9C6.55228 9 7 9.44771 7 10V11C7 13.7614 9.23858 16 12 16C14.7614 16 17 13.7614 17 11V10C17 9.44771 17.4477 9 18 9C18.5523 9 19 9.44771 19 10V11C19 14.5273 16.391 17.4453 12.9976 17.9295C12.9992 17.9528 13 17.9763 13 18V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V18C11 17.9763 11.0008 17.9528 11.0024 17.9295C7.60896 17.4453 5 14.5273 5 11V10C5 9.44771 5.44772 9 6 9Z"
                fill="white"
            />
        </svg>
    );
};
